@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --sat: env(safe-area-inset-top);
}

.safe-top {
  padding-top: var(--sat);
}

.img-background {
  position: relative;
  height: 100%;
  overflow-y: auto;
}

.img-background::before {
  content: '';
  position: fixed;
  inset: 0;
  background-image: url('/public/head.png');
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;
  opacity: 0.3;
  z-index: 0;
  pointer-events: none;
}

.img-background > * {
  position: relative;
  z-index: 1;
}
